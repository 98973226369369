import React from 'react'
import styled from 'styled-components'
import Icon from './Icon'

/**
 * Icon
 */
import { PersonBoundingBox } from '@styled-icons/bootstrap/'
import {University} from '@styled-icons/fa-solid'
import {Amazon} from '@styled-icons/boxicons-logos'
import {Mail} from '@styled-icons/entypo'
import {Cake} from '@styled-icons/material'

const Container = styled.div`
  max-width: 40rem;
  margin: auto;
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px dotted;
`

const NameContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: ${(props) => props.theme.fontSizes.subtitle2};
`

const Name = styled.p`
`
const ContentText = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.subtitle1};
  flex:3;
`

const CustomLink = styled.a`
  text-decoration: none;
  color: #DED46E;
`

const DetailContainer = styled.div`
  margin: 3rem 0;
`

const DetailTitle = styled.p`
  font-size: ${(props) => props.theme.fontSizes.headline4};
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 4px solid #0a84ff;
`

const DetailContent = styled.div`
  white-space: pre-wrap;
  line-height: 2.3;
  border: 1px dotted;
  padding: 1rem;
`

const AboutContent = (props: any) => {
  const Data = [
    {
      content: 'Name',
      text: '上島 和也 (Kazuya Uejima)',
      icon: PersonBoundingBox
    },
    {
      content: 'Birthday',
      text: '1997/09/22',
      icon: Cake
    },
    {
      content: 'University',
      text: '日本工学院八王子専門学校 ITカレッジ ITスペシャリスト科',
      icon: University
    },
    {
      content: 'Wish list',
      text: '干し芋リスト',
      icon: Amazon,
      link: "https://www.amazon.jp/hz/wishlist/ls/CP2HC39SP4DE?ref_=wl_share"
    },
    {
      content: 'Mail',
      text: 'contact[at]moke.dev',
      icon: Mail
    }
  ]

  const ProfileDetail = 'はじめまして。\n\n幼い時に見た『サマーウォーズ』を見てPCの世界と言うのにとても興味がわき、専門学校に入学してから本格的にプログラミングを触り始めました。\n最初は大規模なトラフィックを処理しているサーバー等のインフラ系に興味をもち勉強を開始していきコンテスト等で受賞したのをきっかけに、サービスを自分で手がけたいと思いレイヤーの高いフロントの世界に飛び込みました。\n\n現在はReact+TypeScriptを中心にフロントエンドエンジニアとして活動してます。'


  return (
    <Container>
      {Data.map((item, index) => {
        return (
          <Content>
            <Icon>
                <item.icon />
              </Icon>
            <NameContent>
              <Name>{item.content}</Name>
            </NameContent>
            <ContentText>{item.link ? <CustomLink href={item.link} target="_blank" rel="noopener">{item.text}</CustomLink> : item.text}</ContentText>
          </Content>
        )
      })}
      <DetailContainer>
        <DetailTitle>自分について</DetailTitle>
        <DetailContent>
          {ProfileDetail}
        </DetailContent>
      </DetailContainer>
    </Container>
  )
}
export default AboutContent