import React from 'react';
import styled, { keyframes } from 'styled-components';

import Icon from './Icon';

/**
 * icon
 */
import { Trophy } from '@styled-icons/boxicons-regular';
import { WorkOutline } from '@styled-icons/material';
import { EventNote } from '@styled-icons/material-twotone';

/**
 * interface
 */
interface IconColorProps {
  iconColor: string;
}

const Wrap = keyframes`
  100% {
    transform: translateY(100%);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapContainer = styled.div`
  overflow: hidden;
  position: relative;
  padding: 0 1rem;
  /* &::before{
    animation: ${Wrap} 6s cubic-bezier(.4, 0, .2, 1) forwards;
  background: #fff;
  content: '';
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left:0;
  z-index: 1;
  } */
`;

const TimeLineContainer = styled.div`
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 4px;
    background: #5e5ce6;

    left: 50%;
    margin-left: -2px;
    @media (max-width: 1400px) {
      left: -1px;
    }
  }
`;

const CircleImage = styled.div<Pick<IconColorProps, 'iconColor'>>`
  position: absolute;
  top: 8px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px ${(props) => props.iconColor},
    inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);

  width: 30px;
  height: 30px;
  left: 50%;
  margin-left: -24px;
  margin-top: 15px;
  padding: 0.5rem;
  background-color: #fff;
  color: ${(props) => props.iconColor};
  @media (max-width: 1400px) {
    left: 0px;
  }
`;

const CircleAnimation = keyframes`
   from {
    box-shadow: 0 0 0 6px #5e5ce6, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
  }
  to {
    box-shadow:0 0 0 4px #5e5ce6, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);;
  }
`;

const Content = styled.div`
  position: relative;
  margin: 2em 0;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
  &::after {
    content: '';
    display: table;
    clear: both;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* &:hover {
    ${CircleImage} {
      animation: ${CircleAnimation} 1.2s infinite;
    }
  } */
`;

const ItemsContainer = styled.div`
  position: relative;
  background: #54636d;
  border-radius: 2px;
  color: white;
  padding: 1.6em;
  width: 36%;
  margin: 0 5%;
  transition: all 265ms ease-in;
  @media (max-width: 1400px) {
    margin-right: 30px;
    width: auto;
    float: none;
    margin: 0 0 0 2rem;
  }
  &:hover {
    background: #333c42;
  }
`;

const RightItemsContainer = styled(ItemsContainer)`
  float: right;
`;

const Title = styled.p`
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes.headline5};
  margin-bottom: 0.5rem;
  text-align: center;
`;

const Day = styled.p`
  font-size: ${(props) => props.theme.fontSizes.caption};
  text-align: center;
  margin-bottom: 2rem;
`;

const Tag = styled.p`
  font-size: ${(props) => props.theme.fontSizes.body1};
  font-weight: bold;
  margin-bottom: 1rem;
  border-bottom: 2px solid #5e5ce6;
  display: inline-block;
  padding-bottom: 0.1rem;
`;

const Caption = styled.p`
  white-space: pre-wrap;
  padding: 1rem;
  position: relative;
  line-height: 1.5;
  &::before {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-left: solid 2px #5e5ce6;
    border-top: solid 2px #5e5ce6;
    top: 0;
    left: 0;
  }
  &::after {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-right: solid 2px #5e5ce6;
    border-bottom: solid 2px #5e5ce6;
    bottom: 0;
    right: 0;
  }
`;

const ContinuedText = styled.p`
  border: 3px solid #5e5ce6;
  display: inline-block;
  padding: 1rem;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.headline5};
  border-radius: 0.5rem;
  text-align: center;
`;

const TimeLine: React.FC = (props: any) => {
  const ExperiencesData = [
    {
      day: '2020-09-01~',
      title: 'SAiTech',
      tag: 'インターン',
      caption:
        'エンジニアスクールの講師をやらせて頂いています。\n20人弱の生徒を対象にZoomを使って、授業等を行っております。',
      icon: WorkOutline,
      color: '#c0c0c0',
    },
    {
      day: '2019-08-01 ~ 2020-03-01',
      title: 'NexusLink株式会社',
      tag: 'インターン',
      caption:
        '受託案件や自社開発を行っておりました。\nエンジニアがいない環境でのjoinだったので次につながる様に社内エンジニア部門の環境構築をやらせて頂きました。',
      icon: WorkOutline,
      color: '#c0c0c0',
    },
    {
      day: '2019-12-30',
      title: '冬コミサークル参加',
      tag: 'イベント',
      caption:
        '冬のコミックマーケットにて、所属サークルで技術本の方を書きました。ごった煮本として部員全員の得意な分野や現在やっている事についてまとめた物になります。',
      icon: EventNote,
      color: '#8c4841',
    },
    {
      day: '2019-12-07',
      title: 'ICTSC2019 出場',
      tag: 'コンテスト',
      caption:
        '4回目の出場です。今回は経験のある3年生チームと一緒に出場しました。3年生が主体となり後輩たちに指導をしていきチーム一丸となって取り組んで行けたかとおもいます。',
      icon: Trophy,
      color: '#e6b422',
    },
    {
      day: '2019-05-25',
      title: 'SECCON Beginners CTF出場',
      tag: 'コンテスト',
      caption:
        '3回目の出場です。新入生と一緒にチームを組みながら勉強会等を開いて出場しました。',
      icon: Trophy,
      color: '#e6b422',
    },
    {
      day: '2019-02-01 ~ 2019-08-15',
      title: 'Gojuon Inc.',
      tag: 'インターン',
      caption:
        '六本木にあるベンチャー企業でのインターン\n開発は学生エンジニア3人。PWA,SPAでの情報管理webサイトで新規事業開発を行いました。\n主にフロント側とサーバーサイドを担当。\n後日管理側もリファクタリングで全て作り直し作業を行いました',
      icon: WorkOutline,
      color: '#c0c0c0',
    },
    {
      day: '2018-12-19',
      title: 'ICTSC2018 出場',
      tag: 'コンテスト',
      caption:
        '3回目の出場です。新入生と一緒にチームを組みながら勉強会等を開いて出場しました。',
      icon: Trophy,
      color: '#e6b422',
    },
    {
      day: '2018-06-01 ~ 2019-01-03',
      title: 'Entershare',
      tag: 'インターン',
      caption:
        '新宿のベンチャー企業で初めてのインターン。\n開発は主に2人もしくは1人で担当。主な業務はwordpress\nここで開発する上でのノウハウや基本的なことを学ぶことができました',
      icon: WorkOutline,
      color: '#c0c0c0',
    },
    {
      day: '2018-04-22',
      title: 'Spajam道場出場',
      tag: 'コンテスト',
      caption:
        '学生限定でのアプリコンテスト。\nチーム4人で参加し自分は主にfirebase Database、UI部分、プレゼンを担当。\nプレゼンに関しては投票で１位を頂き人前で話すことに対して抵抗感が和らいだと思う',
      icon: Trophy,
      color: '#e6b422',
    },
    {
      day: '2018-03-04',
      title: 'ICTSC9本選出場',
      tag: 'コンテスト',
      caption:
        '2回目の挑戦。参加したことない３名を入れてコンテストに参加しました\n自分は主にサーバーサイドを担当。人に物を教える大変さと自分の知見不足を改めて実感',
      icon: Trophy,
      color: '#e6b422',
    },
    {
      day: '2017-12-01',
      title: 'MBSD2017第5位',
      tag: 'コンテスト',
      caption:
        '予め脆弱性のあるサーバが渡され、発見修正方法を見つけポイントで競うコンテスト\nチームメンバー4人で参加し、セキュリティの事について深く知る事ができた。',
      icon: Trophy,
      color: '#e6b422',
    },
    {
      day: '2017-08-27',
      title: 'ICTSC8優秀賞',
      tag: 'コンテスト',
      caption:
        'サーバー、ネットワークのトラブルシューティングを行いポイント制で競うコンテスト\nチームメンバー5人で参加し、自分は主にサーバーサイド担当\n２問完答することができ少しでもチームに貢献できた',
      icon: Trophy,
      color: '#e6b422',
    },
    {
      day: '2017-06-04',
      title: 'Spajam2017出場',
      tag: 'コンテスト',
      caption:
        '約１日で季節に沿ったアプリを作るコンテスト\n星を結ぶことによって自分だけの星座を作るアプリの作成\nチームメンバー4人で参加し、自分はプレゼンを主に担当。\n初めてのコンテスト参加で現役エンジニアの方たちの技術力の高さを実感した',
      icon: Trophy,
      color: '#e6b422',
    },
  ];
  return (
    <Container>
      <ContinuedText>to be continued...</ContinuedText>
      <WrapContainer>
        <TimeLineContainer>
          {ExperiencesData.map((item, index) => {
            return (
              <Content>
                <CircleImage iconColor={item.color}>
                  <Icon>
                    <item.icon />
                  </Icon>
                </CircleImage>
                {index % 2 === 0 ? (
                  <ItemsContainer>
                    <Tag>{item.tag}</Tag>
                    <Title>{item.title}</Title>
                    <Day>{item.day}</Day>
                    <Caption>{item.caption}</Caption>
                  </ItemsContainer>
                ) : (
                  <RightItemsContainer>
                    <Tag>{item.tag}</Tag>
                    <Title>{item.title}</Title>
                    <Day>{item.day}</Day>
                    <Caption>{item.caption}</Caption>
                  </RightItemsContainer>
                )}
              </Content>
            );
          })}
        </TimeLineContainer>
      </WrapContainer>
      <ContinuedText>専門入学 </ContinuedText>
    </Container>
  );
};

export default TimeLine;
