import React from 'react';
import * as Theme from './theme/theme';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/*
 * components
 */
import MainPage from './pages/MainPage';

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */
`;

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path='/'>
            <MainPage />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
