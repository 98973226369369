import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import moke from '../assets/images/moke.jpg';

/**
 * pages
 */
import SiteContentPage from './SiteContentPage';
import ProfilePage from './ProfilePage';
import AboutPage from './AboutPage';
import ExperiencesPage from './ExperiencesPage';
import WorkPage from './WorkPage';
import SkillsPage from './SkillsPage';
import UserIcon from '../assets/images/moke.jpg';

/*
 * components
 */
import { Route, Switch } from 'react-router-dom';

interface MainPageProps {}

const Container = styled.div`
  display: grid;
  height: 100vh;
  @media (min-width: 901px) {
    grid-template: 1fr/20rem 1fr;
  }
`;

const Content = styled.div`
  @media (min-width: 901px) {
    overflow-y: auto;
  }
`;

const MainPage: React.FC<MainPageProps> = (props) => {
  return (
    <>
      <Helmet
        title={'Hello World'}
        meta={[
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@uecahn9220' },
          { name: 'mobile-web-app-capable', content: 'yes' },
          { name: 'apple-mobile-web-app-title', content: 'portfolio' },
          { name: 'author', content: 'Kazuya Uejima' },
          { name: 'theme-color', content: '#fff' },
          { property: 'og:image', content: `https://moke.dev${moke}` },
          { property: 'og:title', content: `Kazuya uejima's Portfolio Site` },
          {
            property: 'og:description',
            content:
              'Kazuya Uejimaのポートフォリオサイトです。インターンの経歴や開発しているものなどを紹介しています。',
          },
          { property: 'og:url', content: `https://moke.dev/` },
          { property: 'og:type', content: 'website' },
        ]}
      />
      <Container>
        <SiteContentPage
          name={'Kazuya Uejima'}
          job={'FrontEnd Engineer'}
          image={UserIcon}
          address={'Tokyo/Japan'}
          memo={[
            'このサイトを見てくれてありがとうございます。/nフロントエンドエンジニアとして現在就活中です。',
            'Thank you for visiting this site./n I am currently working as a front-end engineer.',
          ]}
        />
        <Content>
          <Switch>
            <Route exact path='/' component={ProfilePage} />
            <Route path='/about' component={AboutPage} />
            <Route exact path='/experiences' component={ExperiencesPage} />
            <Route path='/works' component={WorkPage} />
            <Route exact path='/skills' component={SkillsPage} />
            {/* <Route exact path='/posts' component={PostsPage} /> */}
          </Switch>
        </Content>
      </Container>
    </>
  );
};

export default MainPage;
