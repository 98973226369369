import React from 'react';
import styled from 'styled-components';
import ProfileContent from '../components/AboutContent'

const Container = styled.div``;

const Content = styled.div`
  padding: 0 1rem;
  background-color: #fff;
    box-sizing: border-box;
    background-attachment: scroll;
`;

const TitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  /* color: rgba(36, 45, 46, 0.1); */
  font-family: 'Roboto';
  font-weight: 700;
  line-height: 1.5;
  padding: 2rem;
  font-size: ${(props) => props.theme.fontSizes.headline3};
  @media (max-width: 900px){
    font-size: ${(props) => props.theme.fontSizes.headline4};
    padding: 2rem 1rem;
  }
`;

const FirstLetter = styled.span`
  font-size: ${(props) => props.theme.fontSizes.headline1};
  color: #0a84ff;
  font-weight: 600;
  display: inline-block;
  position: relative;
  &::before {
    position: absolute;
    height: 6px;
    bottom: 1rem;
    content: attr(data-first-letter);
    background-color: #0a84ff;
    overflow: hidden;
  }
  @media (max-width: 900px){
    font-size: ${(props) => props.theme.fontSizes.headline2};
  }
`;


const ProfilePage: React.FC = () => {
  return (
    <Container>
      <TitleContent>
        <Title>
          <FirstLetter data-first-letter='P'>P </FirstLetter>rofile
        </Title>
      </TitleContent>
      <Content>
        <ProfileContent />
      </Content>
    </Container>
  );
};

export default ProfilePage;
