import {
  html,
  css,
  vue,
  node,
  bootstrap,
  javascript,
  typescript,
  dart,
  haskell,
  go,
  java,
  python,
  ruby,
  php,
  react,
  nuxt,
  django,
  rails,
  laravel,
  flutter,
  mysql,
  aws,
  apollographql,
  docker,
  firebase,
  github,
  gitlab,
  graphql,
  postgresql,
  webpack,
  heroku,
  wordpress,
} from './icon';

export const SkillsData = {
  programmingLanguage: [
    html,
    css,
    javascript,
    typescript,
    go,
    python,
    ruby,
    php,
    java,
    dart,
    haskell,
  ],
  framework: [
    react,
    vue,
    nuxt,
    node,
    bootstrap,
    django,
    rails,
    laravel,
    flutter,
  ],
  database: [mysql, postgresql, apollographql, graphql],
  other: [aws, firebase, heroku, docker, webpack, github, gitlab, wordpress],
};
