import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';
import { Twitter, Github, FacebookSquare } from '@styled-icons/boxicons-logos';
import { PersonFill } from '@styled-icons/bootstrap';
import { Analytics, Work } from '@styled-icons/material';
import { Zap } from '@styled-icons/octicons';

import Icon from '../components/Icon';

interface ProfileProps {
  name: string;
  job: string;
  image: string;
  address: string;
  memo: Array<string>;
}

interface CustomLinkColorProps {
  color: string;
}

/*
 * svg Icon
 */
// import mapPin from '../../assets/svg/map-pin.svg';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.black30};
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-template: 1fr;
  padding: 1rem;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: 900px) {
    display: grid;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: row;
  }
`;

const Image = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: rgba(97, 188, 255, 0.3);
  margin: 1rem 0;
  @media (max-width: 900px) {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    order: 1;
  }
`;

const Name = styled.h4`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.headline4};
  @media (max-width: 900px) {
    font-size: ${(props) => props.theme.fontSizes.headline6};
    order: 2;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-width: 1px 0 1px 0;
  border-color: ${(props) => rgba(props.theme.colors.white, 0.08)};
  border-style: solid;
  width: 100%;
  padding: 2rem 0;
  @media (max-width: 900px) {
    margin: 0.5rem 0;
    display: grid;
    padding: 0.5rem 0;
    grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
  }
`;

const CustomLink = styled(Link)<Pick<CustomLinkColorProps, 'color'>>`
  position: relative;
  margin: 0.5rem 0;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  /* padding: 0.5rem; */
  @media (min-width: 901px) {
    padding: 0 1rem 0.5rem 0;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    /* background-color: rgba(0, 0, 0, 0.4); */
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform 0.3s;
    border: 1px solid transparent;
    background-color: ${(props) => rgba(props.color, 0.4)};
    @media (max-width: 900px) {
      width: 70%;
    }
  }

  &:hover {
    /* color: rgba(255, 255, 255, 0.9); */
    &::after {
      transform: scaleX(1);
    }
  }
`;

const ExternalLink = styled.a`
  display: inline-block;
`;

const SNSContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin: 2rem 0;
  @media (max-width: 900px) {
    margin: 0;
  }
`;

/**
 * icon animation
 */

const SNSContent = styled.div`
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
`;

// Twitter
const TwitterIcon = styled(Twitter)`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  height: 2rem;
  width: 2rem;
  /* transition: color 0.25s; */
  transition: all 265ms ease-out;
  border-radius: 50%;
  @media (max-width: 900px) {
    top: 5px;
    left: 5px;
  }
`;

const TwitterLink = styled(ExternalLink)`
  &:before {
    content: ' ';
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: block;
    background: #1da1f2;
    transition: all 265ms ease-out;
    @media (max-width: 900px) {
      width: 40px;
      height: 40px;
    }
  }

  &:hover:before {
    transform: scale(0);
    transition: all 265ms ease-in;
  }

  &:hover {
    ${TwitterIcon} {
      color: #1da1f2;
      transform: scale(1.6);
      transition: all 265ms ease-in;
    }
  }
`;

// github
const GithubIcon = styled(Github)`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #24292e;
  height: 2rem;
  width: 2rem;
  /* transition: color 0.25s; */
  transition: all 265ms ease-out;
  border-radius: 50%;
  @media (max-width: 900px) {
    top: 5px;
    left: 5px;
  }
`;

const GithubLink = styled(ExternalLink)`
  &:before {
    content: ' ';
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: block;
    background: #f6f8fa;
    transition: all 265ms ease-out;
    @media (max-width: 900px) {
      width: 40px;
      height: 40px;
    }
  }

  &:hover:before {
    transform: scale(0);
    transition: all 265ms ease-in;
  }

  &:hover {
    ${GithubIcon} {
      color: #f6f8fa;
      transform: scale(1.6);
      transition: all 265ms ease-in;
    }
  }
`;

// facebook
const FacebookIcon = styled(FacebookSquare)`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  height: 2rem;
  width: 2rem;
  /* transition: color 0.25s; */
  transition: all 265ms ease-out;
  border-radius: 50%;
  @media (max-width: 900px) {
    top: 5px;
    left: 5px;
  }
`;

const FacebookLink = styled(ExternalLink)`
  &:before {
    content: ' ';
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: block;
    background: #3b5998;
    transition: all 265ms ease-out;
    @media (max-width: 900px) {
      width: 40px;
      height: 40px;
    }
  }

  &:hover:before {
    transform: scale(0);
    transition: all 265ms ease-in;
  }

  &:hover {
    ${FacebookIcon} {
      color: #3b5998;
      transform: scale(1.6);
      transition: all 265ms ease-in;
    }
  }
`;

const SiteContentPage: React.FC<ProfileProps> = (props) => {
  const LinkData = [
    // {
    //   name: 'Profile',
    //   path: '',
    //   icon: Desktop,
    //   color: '#30d158',
    // },
    {
      name: 'About',
      path: 'about',
      icon: PersonFill,
      color: '#0a84ff',
    },
    {
      name: 'Experiences',
      path: 'experiences',
      icon: Analytics,
      color: '#5e5ce6',
    },
    {
      name: 'Works',
      path: 'works',
      icon: Work,
      color: '#ff9f0a',
    },
    {
      name: 'Skills',
      path: 'skills',
      icon: Zap,
      color: '#ff375f',
    },
    // {
    //   name: 'Posts',
    //   path: 'posts',
    //   icon: LocalPostOffice,
    //   color: '#bf5af2',
    // },
  ];

  return (
    <Container>
      <UserInfo>
        <Name>{props.name}</Name>
        <Image src={props.image} />
      </UserInfo>
      <LinkContainer>
        {LinkData.map((item: any, index: number) => {
          return (
            <CustomLink color={item.color} to={`/${item.path}`} key={index}>
              <Icon>
                <item.icon />
              </Icon>
              {item.name}
            </CustomLink>
          );
        })}
      </LinkContainer>
      <SNSContainer>
        <SNSContent>
          <TwitterLink href='https://twitter.com/uechan9220'>
            <TwitterIcon />
          </TwitterLink>
        </SNSContent>
        <SNSContent>
          <GithubLink href='https://github.com/mokeeemokeee'>
            <GithubIcon />
          </GithubLink>
        </SNSContent>
        <SNSContent>
          <FacebookLink href='https://www.facebook.com/UejimaKazuya/'>
            <FacebookIcon />
          </FacebookLink>
        </SNSContent>
      </SNSContainer>
    </Container>
  );
};

export default SiteContentPage;
