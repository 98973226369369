/**
 * icons
 */
import {
  Html5,
  Css3,
  Vuejs,
  Nodejs,
  Bootstrap,
} from '@styled-icons/boxicons-logos';
import {
  Javascript,
  Typescript,
  Dart,
  Haskell,
  Go,
  Java,
  Python,
  Ruby,
  Php,
  ReactLogo,
  NuxtDotJs,
  Django,
  Rubyonrails,
  Laravel,
  Flutter,
  Mysql,
} from '@styled-icons/simple-icons';
import {
  Amazonaws,
  Apollographql,
  Docker,
  Firebase,
  Github,
  Gitlab,
  Graphql,
  Postgresql,
  Webpack,
  Heroku,
  Wordpress,
} from 'styled-icons/simple-icons';

export const html = {
  name: 'html',
  color: '#fff',
  backgroundColor: '#f06529',
  icon: Html5,
  path: 'https://developer.mozilla.org/ja/docs/Web/Guide/HTML/HTML5/HTML5_element_list',
};

export const css = {
  name: 'css',
  color: '#fff',
  backgroundColor: '#2965f1',
  icon: Css3,
  path: 'https://devdocs.io/css/',
};

export const javascript = {
  name: 'javascript',
  color: '#f0db4f',
  backgroundColor: '#fff',
  icon: Javascript,
  path: 'https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference',
};

export const typescript = {
  name: 'typescript',
  color: '#007acc',
  backgroundColor: '#fff',
  icon: Typescript,
  path: 'https://www.typescriptlang.org/docs/',
};

export const go = {
  name: 'go',
  color: '#5AC9E2',
  backgroundColor: '#fff',
  icon: Go,
  path: 'https://golang.org/doc/',
};

export const python = {
  name: 'python',
  color: '#4b8bbe',
  backgroundColor: '#fff',
  icon: Python,
  path: 'https://docs.python.org/ja/3/',
};

export const ruby = {
  name: 'ruby',
  color: '#c00',
  backgroundColor: '#fff',
  icon: Ruby,
  path: 'https://www.ruby-lang.org/ja/documentation/',
};

export const php = {
  name: 'php',
  color: '#787cb5',
  backgroundColor: '#fff',
  icon: Php,
  path: 'https://www.php.net/manual/ja/index.php',
};

export const java = {
  name: 'java',
  color: '#D35555',
  backgroundColor: '#fff',
  icon: Java,
  path: 'https://docs.oracle.com/javase/jp/8/docs/',
};

export const dart = {
  name: 'dart',
  color: '#0075ba',
  backgroundColor: '#fff',
  icon: Dart,
  path: 'https://dart.dev/guides',
};

export const haskell = {
  name: 'haskell',
  color: '#999999',
  backgroundColor: '#fff',
  icon: Haskell,
  path: 'https://www.haskell.org/documentation/',
};

export const react = {
  name: 'React',
  color: '#61dbfb',
  backgroundColor: '#fff',
  icon: ReactLogo,
  path: 'https://ja.reactjs.org/docs/hello-world.html',
};

export const vue = {
  name: 'Vue',
  color: '#42b883',
  backgroundColor: '#fff',
  icon: Vuejs,
  path: 'https://vuejs.org/v2/guide/index.html',
};

export const nuxt = {
  name: 'Nuxt',
  color: '#3b8070',
  backgroundColor: '#fff',
  icon: NuxtDotJs,
  path: 'https://ja.nuxtjs.org/guide/',
};

export const node = {
  name: 'nodejs',
  color: '#6ba063',
  backgroundColor: '#fff',
  icon: Nodejs,
  path: 'https://nodejs.org/ja/docs/',
};

export const bootstrap = {
  name: 'Bootstrap',
  color: '#563d7c',
  backgroundColor: '#fff',
  icon: Bootstrap,
  path: 'https://getbootstrap.jp/docs/4.2/getting-started/introduction/',
};

export const django = {
  name: 'Django',
  color: '#092e20',
  backgroundColor: '#fff',
  icon: Django,
  path: 'https://docs.djangoproject.com/ja/3.1/',
};

export const rails = {
  name: 'Rubyonrails',
  color: '#c00',
  backgroundColor: '#fff',
  icon: Rubyonrails,
  path: 'https://railsdoc.com/',
};

export const laravel = {
  name: 'Laravel',
  color: '#fb503b',
  backgroundColor: '#fff',
  icon: Laravel,
  path: 'https://readouble.com/laravel/8.x/en/installation.html',
};

export const flutter = {
  name: 'Flutter',
  color: '#73C3F3',
  backgroundColor: '#fff',
  icon: Flutter,
  path: 'https://flutter.dev/docs',
};

export const mysql = {
  name: 'Mysql',
  color: '#00758f',
  backgroundColor: '#fff',
  icon: Mysql,
  path: 'https://dev.mysql.com/doc/refman/8.0/en/',
};

export const postgresql = {
  name: 'Postgresql',
  color: '#336791',
  backgroundColor: '#fff',
  icon: Postgresql,
  path: 'https://www.postgresql.org/docs/',
};

export const apollographql = {
  name: 'ApolloGraphql',
  color: '#112B49',
  backgroundColor: '#fff',
  icon: Apollographql,
  path: 'https://www.apollographql.com/docs/react/',
};

export const graphql = {
  name: 'Graphql',
  color: '#e535ab',
  backgroundColor: '#fff',
  icon: Graphql,
  path: 'https://graphql.org/learn/',
};

export const aws = {
  name: 'AWS',
  color: '#ff9900',
  backgroundColor: '#fff',
  icon: Amazonaws,
  path: 'https://docs.aws.amazon.com/',
};

export const firebase = {
  name: 'Firebase',
  color: '#f5820d',
  backgroundColor: '#fff',
  icon: Firebase,
  path: 'https://firebase.google.com/docs',
};

export const heroku = {
  name: 'Heroku',
  color: '#6762a6',
  backgroundColor: '#fff',
  icon: Heroku,
  path: 'https://dashboard.heroku.com/',
};

export const wordpress = {
  name: 'Wordpress',
  color: '#00749C',
  backgroundColor: '#fff',
  icon: Wordpress,
  path: 'https://ja.wordpress.com/',
};

export const docker = {
  name: 'docker',
  color: '#2496ED',
  backgroundColor: '#fff',
  icon: Docker,
  path: 'https://docs.docker.jp/',
};

export const webpack = {
  name: 'webpack',
  color: '#8dd6f9',
  backgroundColor: '#fff',
  icon: Webpack,
  path: 'https://webpack.js.org/',
};

export const github = {
  name: 'github',
  color: '#000',
  backgroundColor: '#fff',
  icon: Github,
  path: 'https://docs.github.com/ja',
};

export const gitlab = {
  name: 'gitlab',
  color: '#fc6d26',
  backgroundColor: '#fff',
  icon: Gitlab,
  path: 'https://docs.gitlab.com/',
};
