const colors = {
  dark: '#001064',
  light: '#5f5fc4',
  primary: '#283593',
  blue: '#2179ee',
  green: '#00cc9a',
  coral: '#ff6759',
  gold: '#f0b95b',
  purple: '#7537ef',
  white: '#ffffff',
  black: '#000000',

  black10: '#262626',
  black20: '#1a1a1a',
  black30: '#1e2a3a',
  black40: '#111821',

  grey10: '#f3f4f8',
  grey20: '#e1e5eb',
  grey30: '#c2c6cc',
  grey40: '#9ea2a8',
  grey50: '#686c73',
  grey60: '#30363d',
};

const secondaryColors = {
  blue10: '#ade7ff',
  blue20: '#61bcff',
  blue30: '#2179ee',
  blue40: '#1f4ab4',
  blue50: '#1d2064',
  green10: '#b5ffcb',
  green20: '#5dffa3',
  green30: '#00cc9a',
  green40: '#219a8a',
  green50: '#183f51',
  purple10: '#dec7ff',
  purple20: '#a673ff',
  purple30: '#7537ef',
  purple40: '#4e23b6',
  purple50: '#2d1b64',
  coral10: '#ffc6b3',
  coral20: '#ff8e75',
  coral30: '#ff6759',
  coral40: '#eb312a',
  coral50: '#7b1e30',
  gold10: '#ffedc2',
  gold20: '#ffda8b',
  gold30: '#f0b95b',
  gold40: '#e5a229',
  gold50: '#6a4a24',
};

const fontSizes = {
  headline1: '96px',
  headline2: '60px',
  headline3: '48px',
  headline4: '34px',
  headline5: '24px',
  headline6: '20px',
  subtitle1: '16px',
  subtitle2: '14px',
  body1: '16px',
  body2: '14px',
  button: '14px',
  caption: '12px',
  overline: '10px',
};

export { colors, secondaryColors, fontSizes };
