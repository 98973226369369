import React from 'react'
import styled from 'styled-components'
import Icon from './Icon'
/* image */
import shalust from '../assets/images/works/shalust.png'
import MEME from '../assets/images/works/MEME.jpg'
import machat from '../assets/images/works/machat.png'
import suzume from '../assets/images/works/suzume.png'
import notlook from '../assets/images/works/notlook.jpg'
import npxUechan from '../assets/images/works/npxUechan.png'

/* icon */
import { react, go, docker, aws, nuxt, firebase, heroku, rails, apollographql, graphql, vue, wordpress } from '../data/icon'

/**
 * interface
 */
interface BackgroundImageProps {
  backgroundImage: string;
}

interface IconColor {
  color: string
  backgroundColor: string
}

const Content = styled.div`
  
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
  > ${Content}:first-child{
    @media (min-width: 576px){
      grid-column: 1 / span 2;
    }
  }
`

const CustomLink = styled.a`
  background: white;
  text-decoration: none;
  color: #444;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  top: 0;
  transition: all .1s ease-in;
  &:hover {
    top: -2px;
    box-shadow: 0 4px 5px rgba(0,0,0,0.2);
  }
`

const ImageCover = styled.div<Pick<BackgroundImageProps, 'backgroundImage'>>`
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
  background-image: url(${props => props.backgroundImage});
  border: 1px solid;
`

const ArticleContent = styled.div`
  padding: 20px;
  -webkit-box-flex: 1;
  flex: 1;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
`

const InfoContent = styled.div`
  margin-bottom: 3rem;
`

const Title = styled.p`
  font-size: ${(props) => props.theme.fontSizes.headline5};
  font-weight: bold;
`

const Article = styled.p`
  margin-top: 1rem;
  line-height: 1.4;
`

const SkillContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(2.5rem, 1fr));
  grid-gap: 0.5rem;
`

const IconContent = styled.div<Pick<IconColor, 'color' | 'backgroundColor'>>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  width: 2.5rem;
  height: 2.5rem;
`

const WorkContent: React.FC = (props: any) => {
  const Data = [
    {
      image: shalust,
      title: 'Shalust(現在作成中)',
      link: 'https://github.com/MokeeeMokeee/Shalust',
      article: '『誰でも気軽に投稿！』をモットーに現在はβ版公開のために開発を行っております。',
      skill: [react, go, docker, apollographql, aws]
    },
    {
      image: suzume,
      title: 'suzume',
      link: 'https://suzme.app/',
      article: '最初の開発段階のみ参加させて頂きました。MEMEで得た知識を使い開発の方を行いました。',
      skill: [nuxt, firebase]
    },
    {
      image: MEME,
      title: 'MEME(サービス終了)',
      link: 'https://www.notion.so/MEME-Notes-3bdbbd5336a24c73942b0cab084fa36e',
      article: 'スニーカーヘッズに向けた、国内外のスニーカーブランドの情報を発信するメディアとしてSPA/PWAの要件で開発を行いました。Hasuraというgraphql engineを使用して開発を行いました。',
      skill: [nuxt, rails, graphql, aws, heroku, firebase]
    },
    {
      image: npxUechan,
      title: 'npx 自己紹介',
      link: 'https://github.com/MokeeeMokeee/uechan9220',
      article: 'CUIで自己紹介が出来るように、npx commandを使用して簡単な名刺みたいなの作りました。',
      skill: []
    },
    {
      image: machat,
      title: 'machat',
      link: 'https://machat-70f38.web.app/',
      article: '学校の方で取り組んでいるMARS ProjectでオリジナルのchatAppがほしいとの事で作成しました。',
      skill: [vue, firebase]
    },
    {
      image: notlook,
      title: 'ダメー！(大人の事情)',
      link: 'https://entershare.jp/',
      article: '主にインターンで作った物になります。\nWordpressでのテーマ開発やRuby on Railsを使ったCRMの開発等を行いました。\n諸事情により乗せることができないのでインターン先のリンク張ります',
      skill: [wordpress, rails]
    },
  ]

  return (
    <Container>
      {Data.map((item, index) => {
        return (
          <Content>
            <CustomLink href={item.link} target="_blank" rel="noopener">
              <ImageCover backgroundImage={item.image}></ImageCover>
              <ArticleContent>
                <InfoContent>
                  <Title>{item.title}</Title>
                  <Article>{item.article}</Article>
                </InfoContent>
                <SkillContent>
                  {item.skill?.map((item, index) => {
                    return (
                      <IconContent color={item.color} backgroundColor={item.backgroundColor}>
                        <Icon>
                          <item.icon />
                        </Icon>
                      </IconContent>
                    )
                  })}
                </SkillContent>
              </ArticleContent>
            </CustomLink>
          </Content>
        )
      })}
    </Container>
  )
}

export default WorkContent