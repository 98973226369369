import React from 'react';
import styled from 'styled-components';

const LinkIcon = styled.i`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const Icon: React.FC = ({ className, children }: any) => {
  return <LinkIcon className={className}>{children}</LinkIcon>;
};

export default Icon;
