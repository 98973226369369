import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { SkillsData } from '../data/skills';
import Icon from '../components/Icon';

/**
 * interface
 */
interface LogoProps {
  color: string;
  backgroundColor: string;
  displayNumber: number;
  delayNumber: number;
}

/**
 * style
 */
const scaleUp = keyframes`
  0% { opacity: 0; }
	100% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
`;

const Container = styled.div``;

const Content = styled.div`
  padding: 1rem;
`;

const ContentTitle = styled.p`
  font-size: ${(props) => props.theme.fontSizes.headline5};
  font-weight: bold;
  border-bottom: 2px solid;
  border-color: rgba(0, 0, 0, 0.5);
  padding-bottom: 0.2rem;
  margin-bottom: 1rem;
`;

const CustomLink = styled.a<
  Pick<LogoProps, 'color' | 'backgroundColor' | 'displayNumber' | 'delayNumber'>
>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  transform: scale(0);
  animation: ${scaleUp} 0.5s ease-in-out forwards;
  animation-delay: ${(props) => props.displayNumber / 15 + props.delayNumber}s;
`;

const TitleContent = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  color: rgba(36, 45, 46, 0.1);
  font-family: 'Roboto';
  font-weight: 700;
  line-height: 1.5;
  padding: 2rem;
  font-size: ${(props) => props.theme.fontSizes.headline3};
  @media (max-width: 900px) {
    font-size: ${(props) => props.theme.fontSizes.headline4};
    padding: 2rem 1rem;
  }
`;

const FirstLetter = styled.span`
  font-size: ${(props) => props.theme.fontSizes.headline1};
  color: #ff375f;
  font-weight: 600;
  display: inline-block;
  position: relative;
  &::before {
    position: absolute;
    height: 6px;
    bottom: 1rem;
    content: attr(data-first-letter);
    background-color: #ff375f;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    font-size: ${(props) => props.theme.fontSizes.headline2};
  }
`;

const LogoContent = styled.div`
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  grid-gap: 1rem;
`;

const ContentBlock = styled.div`
  margin-bottom: 3rem;
  &:last-child {
    margin-block-end: 0;
  }
`;

const SkillsPage: React.SFC = () => {
  return (
    <Container>
      <TitleContent>
        <Title>
          <FirstLetter data-first-letter='S'>S </FirstLetter>kills
        </Title>
      </TitleContent>
      <Content>
        <ContentBlock>
          <ContentTitle>言語</ContentTitle>
          <LogoContent>
            {SkillsData.programmingLanguage.map((item, index) => {
              return (
                <CustomLink
                  backgroundColor={item.backgroundColor}
                  color={item.color}
                  href={item.path}
                  displayNumber={index}
                  delayNumber={0}
                  key={index}
                >
                  <Icon>
                    <item.icon />
                  </Icon>
                </CustomLink>
              );
            })}
          </LogoContent>
        </ContentBlock>
        <ContentBlock>
          <ContentTitle>フレームワーク</ContentTitle>
          <LogoContent>
            {SkillsData.framework.map((item, index) => {
              return (
                <CustomLink
                  backgroundColor={item.backgroundColor}
                  color={item.color}
                  href={item.path}
                  displayNumber={index}
                  delayNumber={1}
                  key={index}
                >
                  <Icon>
                    <item.icon />
                  </Icon>
                </CustomLink>
              );
            })}
          </LogoContent>
        </ContentBlock>
        <ContentBlock>
          <ContentTitle>データーベース系</ContentTitle>
          <LogoContent>
            {SkillsData.database.map((item, index) => {
              return (
                <CustomLink
                  backgroundColor={item.backgroundColor}
                  color={item.color}
                  href={item.path}
                  displayNumber={index}
                  delayNumber={2}
                  key={index}
                >
                  <Icon>
                    <item.icon />
                  </Icon>
                </CustomLink>
              );
            })}
          </LogoContent>
        </ContentBlock>
        <ContentBlock>
          <ContentTitle>その他</ContentTitle>
          <LogoContent>
            {SkillsData.other.map((item, index) => {
              return (
                <CustomLink
                  backgroundColor={item.backgroundColor}
                  color={item.color}
                  href={item.path}
                  displayNumber={index}
                  delayNumber={3}
                  key={index}
                >
                  <Icon>
                    <item.icon />
                  </Icon>
                </CustomLink>
              );
            })}
          </LogoContent>
        </ContentBlock>
      </Content>
    </Container>
  );
};

export default SkillsPage;
